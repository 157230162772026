import { useState } from "react"

import { ApiClientCredentials } from "src/components/ApiClients/ApiClientCredentials"
import { ApiClientForm } from "src/components/ApiClients/ApiClientForm"
import { usePostApiClientCompleted } from "src/data/analytics/queries/apiClientAnalyticsQueries"
import {
  useFetchApiClientSecret,
  usePostApiClient,
} from "src/data/apiClients/apiClientQueries"
import { IApiClientPostBody } from "src/data/apiClients/apiClientTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"

export function CreateApiClientDialog() {
  const { t, langKeys } = useTranslate()
  const { goBack } = useRouter()
  const [clientId, setClientId] = useState<string>()

  const postApiClient = usePostApiClient()
  const postApiClientCompleted = usePostApiClientCompleted()

  const formId = "create-api-client-form"

  function handleSubmit(body: IApiClientPostBody) {
    postApiClient.mutate(body, {
      onSuccess: (data) => {
        postApiClientCompleted.mutate()
        setClientId(data.client_id)
      },
    })
  }

  return (
    <div>
      <MDialog
        open={!clientId}
        title={t(langKeys.api_clients_create_title)}
        description={t(langKeys.api_clients_create_body)}
        onClose={() =>
          goBack({ defaultPath: Routes.ApiClientsSetting.location() })
        }
        confirmLabel={t(langKeys.save)}
        formId={formId}
        loading={postApiClient.isLoading}
        error={postApiClient.isError && t(langKeys.failed_general_error_title)}
      >
        <ApiClientForm formId={formId} onSubmit={handleSubmit} />
      </MDialog>

      {clientId && <SuccessDialog clientId={clientId} />}
    </div>
  )
}

function SuccessDialog({ clientId }: { clientId: string }) {
  const { navigate } = useRouter()
  const { t } = useTranslate()

  const fetchApiClientSecret = useFetchApiClientSecret({
    clientId: clientId,
  })

  const clientSecret = fetchApiClientSecret.data?.client_secret

  function handleClose() {
    navigate(Routes.ApiClientsSetting.location())
  }

  return (
    <MDialog
      title={t(langKeys.api_clients_client_credentials_title)}
      open={true}
      confirmLabel={t(langKeys.close)}
      onConfirm={handleClose}
      onClose={handleClose}
    >
      <ApiClientCredentials
        clientId={clientId}
        clientSecret={clientSecret}
        hideBorder={true}
      />
    </MDialog>
  )
}
