import { useState } from "react"
import styled from "styled-components"

import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { useSnackbar } from "notistack"

import { makeProfileCopy } from "src/data/homeProfiles/logic/homeProfiles"
import {
  useDeleteProfile,
  usePostProfile,
} from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { IProfile } from "src/data/homeProfiles/types/homeProfilesTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDivider } from "src/ui/Divider/Divider"
import CopyIcon from "src/ui/icons/copy.svg"
import EditIcon from "src/ui/icons/edit.svg"
import MoreVertIcon from "src/ui/icons/options-horizontal.svg"
import TrashIcon from "src/ui/icons/trash.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function SettingsProfileCard(props: {
  profile: IProfile
  removeValidation: (p: IProfile) => Promise<boolean>
}) {
  const { t } = useTranslate()
  const snackbar = useSnackbar()
  // React Query Hooks
  const deleteProfile = useDeleteProfile()
  const postProfile = usePostProfile()
  const { navigate } = useRouter()

  // Popover conf
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const popoverId = open ? "simple-popover" : undefined
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }
  const editUrl = Routes.MonitoringSettingEdit.location(
    props.profile.id
  ).pathname

  function handleEdit() {
    handleClose()
    navigate(editUrl)
  }

  function handleCopy() {
    postProfile.mutate(makeProfileCopy(props.profile), {
      onSuccess: () => {
        handleClose()
      },
    })
  }

  async function handleDelete() {
    handleClose()
    const canRemove = await props.removeValidation(props.profile)
    if (canRemove) {
      deleteProfile.mutate(props.profile.id, {
        onError: () => {
          snackbar.enqueueSnackbar("Could not delete profile", {
            variant: "error",
          })
        },
      })
    }
  }

  return (
    <CardBox loading={+(deleteProfile.isLoading || postProfile.isLoading)}>
      <StyledLink to={editUrl} underline="never">
        <MText variant="subtitle">
          <span>{props.profile.name}</span>
        </MText>

        <MText variant="body">
          {props.profile.number_of_homes}{" "}
          {t(langKeys.home, {
            count: props.profile.number_of_homes,
          })?.toLocaleLowerCase()}
        </MText>
      </StyledLink>

      <StyledIconButton
        aria-label="settings"
        aria-describedby={popoverId}
        onClick={(e) => {
          handleClick(e)
        }}
      >
        <MoreVertIcon width={24} style={{ transform: "rotate(90deg)" }} />
      </StyledIconButton>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <StyledList dense={false}>
          <ListItem button onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={t(langKeys.edit)} />
          </ListItem>

          <MDivider />

          <ListItem button onClick={handleCopy}>
            <ListItemIcon>
              <CopyIcon height="24" width="24" />
            </ListItemIcon>
            <ListItemText primary={t(langKeys.duplicate)} />
          </ListItem>

          <MDivider />

          <ListItem button onClick={handleDelete}>
            <ListItemIcon>
              <TrashIcon height="24" width="24" />
            </ListItemIcon>
            <ListItemText primary={t(langKeys.delete)} />
          </ListItem>
        </StyledList>
      </Popover>
    </CardBox>
  )
}

const StyledLink = styled(InternalLink)`
  flex-grow: 1;
  padding: ${spacing.L} ${spacing.M} ${spacing.L} ${spacing.L};
  margin-right: ${spacing.M};
  position: relative;
  &:hover {
    background-color: #f7f7f9;
  }
`

const CardBox = styled(Card)<{ loading: number }>`
  opacity: ${({ loading }) => (loading ? 0.2 : 1)};
  display: flex;
  align-items: flex-start;
  position: relative;
`

const StyledIconButton = styled(IconButton)`
  margin-right: ${spacing.S};
  margin-top: ${spacing.S};
  padding: ${spacing.XS};
`

const StyledList = styled(List)`
  padding: 0;
`
