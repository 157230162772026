import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import ApartmentIcon from "src/ui/icons/apartment-building.svg"
import { spacing } from "src/ui/spacing"

export function ApiClientsEmptyState({
  onCreateClientClick,
}: {
  onCreateClientClick: () => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <EmptyState
      icon={<ApartmentIcon />}
      title={t(langKeys.api_clients_empty_title)}
      body={t(langKeys.api_clients_empty_body)}
    >
      <ButtonBox>
        <MButton onClick={onCreateClientClick}>
          {t(langKeys.api_clients_create_client)}
        </MButton>
      </ButtonBox>
    </EmptyState>
  )
}

const ButtonBox = styled.div`
  margin-top: ${spacing.L};
`
