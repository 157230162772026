import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TApiClientEventTypes =
  | "ApiClient Create Initiated"
  | "ApiClient Create Completed"
  | "ApiClient Edit Initiated"
  | "ApiClient Edit Completed"
  | "ApiClient Upgrade Initiated"
  | "ApiClient Delete Initiated"
  | "ApiClient Delete Completed"

function usePostApiClientTrackingEvent<TEventProperties>(
  event: TApiClientEventTypes
) {
  return usePostTrackEventWithParams<TApiClientEventTypes, TEventProperties>({
    event,
  })
}

export function usePostApiClientInitiated() {
  const x = usePostApiClientTrackingEvent("ApiClient Create Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostApiClientCompleted() {
  const x = usePostApiClientTrackingEvent("ApiClient Create Completed")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostApiClientUpgradeInitiated() {
  const x = usePostApiClientTrackingEvent("ApiClient Upgrade Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostApiClientEditInitiated() {
  const x = usePostApiClientTrackingEvent("ApiClient Edit Initiated")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostApiClientEditCompleted() {
  const x = usePostApiClientTrackingEvent("ApiClient Edit Completed")
  return {
    ...x,
    mutate: () => x.mutate({}),
  }
}

export function usePostApiClientDeleteInitiated() {
  const h = usePostApiClientTrackingEvent("ApiClient Delete Initiated")
  return {
    ...h,
    mutate: () => h.mutate({}),
  }
}

export function usePostApiClientDeleteCompleted() {
  const h = usePostApiClientTrackingEvent("ApiClient Delete Completed")
  return {
    ...h,
    mutate: () => h.mutate({}),
  }
}
